import React from 'react'
import { graphql } from 'gatsby'
import Layout from './../components/layout'

import CallToAction from '../components/call-to-action'
import HomePanel from '../components/home-panel'
import FeatureHero from '../components/feature-hero'
import Promotion from '../components/promotion'
import SEO from '../components/seo'

const title = 'Create a professional online store'
const title2 = 'Discover a better way to blog'
const title3 = 'Grow your business with our marketing tools'
const title4 = 'Get fast without the furious'
const title5 = 'Get a free domain with an annual plan*'
const title6 = 'Build the smart way'

const description = `Quickly add products, prices, and all the information you need to make your product stand out online. Creating an e-commerce website has never been so simple.`
const description2 = `Whether your blog is designed to help market your business or to create a place where you can share your ideas and build a community, our blog-creation features make it easy.`
const description3 = `Marketing tools like Google My Business help customers find your business online and in the real world. Now you can create a business listing, display your business on Google Maps, be seen on Google Search, and interact with customer reviews.`
const description4 = `Your site visitors expect your site to load fast, and search engines actually prioritize sites with fast load times. A content delivery network (CDN) makes your site load faster by caching site data closer to your customers. It’s automatically set up for you when you build with SiteBuilder. `
const description5 = `Your domain is more than your website’s address. It’s part of your brand. When you sign up for an annual website plan, we’ll cover the first year of your domain registration.`
const description6 = `Building a website the old way means spending time figuring out complex site builders or spending money hiring designers and developers. Our easy-to-use builder helps you create a smart, responsive site without the big price tag. `

const ctaText = 'Pick Your Plan'

const IndexPage = ({
  data: {
    PanelImage1,
    PanelImage2,
    PanelImage3,
    PanelImage4,
    PanelImage5,
    PanelImage6,
    Header
  }
}) => {
  const startOnboarding = () => {
    window.location.href = process.env.GATSBY_SIGNUP_SITE_URL
  }

  return (
    <Layout isWhiteBanner>
      <SEO
        title='Features'
        keywords={[
          `web hosting`,
          `domain names`,
          `web site`,
          `search engine optimization`,
          `hosting`,
          `servers`
        ]}
        pageType={'product_page'}
        flowType={'none'}
      />
      <FeatureHero gatsbyImage={Header.childImageSharp.fluid} />
      <HomePanel
        gatsbyImage={PanelImage1.childImageSharp.fluid}
        title={title}
        description={description}
        alignment='right'
      />
      <HomePanel
        dark
        gatsbyImage={PanelImage2.childImageSharp.fluid}
        title={title2}
        description={description2}
        alignment='left'
      />
      <HomePanel
        gatsbyImage={PanelImage3.childImageSharp.fluid}
        title={title3}
        description={description3}
        alignment='right'
      />
      <HomePanel
        dark
        gatsbyImage={PanelImage4.childImageSharp.fluid}
        title={title4}
        description={description4}
        alignment='left'
      />
      <HomePanel
        gatsbyImage={PanelImage5.childImageSharp.fluid}
        title={title5}
        description={description5}
        alignment='right'
      />
      <HomePanel
        dark
        gatsbyImage={PanelImage6.childImageSharp.fluid}
        title={title6}
        description={description6}
        alignment='left'
        button={<CallToAction black showBadge={false} ctaText={ctaText} onClick={startOnboarding} />}
      />
      <Promotion />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    PanelImage1: file(relativePath: { eq: "store.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage2: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage3: file(relativePath: { eq: "gmb.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage4: file(relativePath: { eq: "fast.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage5: file(relativePath: { eq: "domain.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage6: file(relativePath: { eq: "getStarted.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Header: file(relativePath: { eq: "header.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
