import styled from 'styled-components'
import Img from 'gatsby-image'

const Hero = styled.div`
  position: relative;
  height: 700px;
  @media (max-width: 800px) {
    max-height: 600px;
  }
  @media (max-width: 450px) {
    height: 60vh;
    max-height: 400px;
  }
`
Hero.content = styled.div`
  display: inline-block;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
  color: white;
`
Hero.text = styled.p`
  font-size: 32pt;
  font-weight: 400;
  @media (max-width: 450px) {
    font-size: 8vw;
  }
`
Hero.Image = styled(Img)`
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 820px;
  max-width: 85vw;
`
Hero.background = styled.div`
  background-color: #0282ed;
  height: 70%;
  @media (max-width: 800px) {
    height: 100%;
  }
`

export default Hero
