import React from 'react'
import PropTypes from 'prop-types'
import Hero from './feature-hero.styles'

const copyText = 'All the tools you need'

const FeatureHero = ({ gatsbyImage }) => {
  return (
    <Hero>
      <Hero.content>
        <Hero.text>{copyText}</Hero.text>
        <Hero.Image fluid={gatsbyImage} />
      </Hero.content>
      <Hero.background />
    </Hero>
  )
}

FeatureHero.propTypes = {
  gatsbyImage: PropTypes.object.isRequired
}

export default FeatureHero
